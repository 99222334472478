import React, { useState, useEffect } from 'react';
// material api
import { Button, Grid, Typography, Box } from '@mui/material';
// component
import SelectBoxComponent from '../../../components/SelectBox.component';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import DeliveryHeaderInfoComponenet from './Order.header.info.componenet';
import * as yup from 'yup';
import { useFormik } from 'formik';

// mock
import { DeliveryManStatus } from '../../../config/status.config';
// api
import { updateOrderStatus } from '../services/serverApi.service';
const validationSchema = yup.object({
  pricePaid: yup.number().required(),
  priceInvoice: yup.number().required(),
});
export default function ChangeStatusComponent({
  orders,
  orderId,
  admin,
  close,
}) {
  const formik = useFormik({
    initialValues: {
      pricePaid: '',
      priceInvoice: '',
    },
    validationSchema,
  });
  const [order, setOrder] = useState(
    (orders || []).find((x) => x._id == orderId),
  );
  const [selectedStatus, setSelectedStatus] = useState(
    order.deliveryManStatus || '',
  );
  const [disableBtn, setDisableBtn] = useState(false);
  const handleStatus = () => {
    const deliveryManId = !!order.deliveryMan ? order.deliveryMan._id : null;

    if (deliveryManId) {
      const pricePayload = {};
      if (
        order.payment?.serviceType == 'Stores' &&
        selectedStatus == 'STARTED_DELIVERY'
      ) {
        pricePayload.invoiceUrl = order.payment?.invoiceUrl || 'url:///';
        pricePayload.pricePaid = formik.values.pricePaid;
        pricePayload.priceInvoice = formik.values.priceInvoice;
      }
      updateOrderStatus(order._id, {
        deliveryManId: deliveryManId,
        status: selectedStatus,
        // invoiceUrl: 'url:///',
        // pricePaid: 0,
        ...pricePayload,
        admin,
      })
        .then(({ data }) => {
          close();
        })
        .catch((err) => console.log(err));
    }
  };
  async function setValue() {
    if (order) {
      formik.setValues({
        pricePaid: order?.payment?.pricePaid,
        priceInvoice: order?.payment?.priceInvoice,
      });
    }
  }
  const priceInvoiceChanged = () => {
    if (!formik.values.priceInvoice) {
      setDisableBtn(true);
    } else {
      console.log('order.payment?.chargeCommissionToCustomer', order.payment);
      const chargeCommissionToCustomer =
        order.payment?.chargeCommissionToCustomer || false;
      console.log('chargeCommissionToCustomer', chargeCommissionToCustomer);
      if (
        chargeCommissionToCustomer ||
        order.payment.storeCommissionType == 'MONTHLY'
      ) {
        formik.setFieldValue('pricePaid', formik.values.priceInvoice);
      } else {
        formik.setFieldValue(
          'pricePaid',
          formik.values.priceInvoice -
            (formik.values.priceInvoice * order.payment.storeCommission) / 100,
        );
      }
      setDisableBtn(false);
    }
  };
  console.log('formik.values.pricePaid', formik.values.pricePaid);

  useEffect(() => {
    setValue();
  }, [order]);
  useEffect(() => {
    priceInvoiceChanged();
  }, [formik.values.priceInvoice]);
  return (
    <div>
      <DeliveryHeaderInfoComponenet order={order} />
      <Box style={{ padding: '5px' }}>
        <SelectBoxComponent
          items={DeliveryManStatus.map((item) => ({
            value: item.value,
            label: item.label,
          }))}
          inputLabel="status"
          onValueChange={(item) => {
            setSelectedStatus(item);
            item == 'STARTED_DELIVERY' && !formik.values.priceInvoice
              ? setDisableBtn(true)
              : setDisableBtn(false);
          }}
          value={selectedStatus}
        />{' '}
        <br />
        {order.payment?.serviceType == 'Stores' &&
          selectedStatus &&
          selectedStatus == 'STARTED_DELIVERY' && (
            <div>
              <CustomInput
                labelText="price Invoice"
                formControlProps={{
                  fullWidth: true,
                }}
                type="number"
                error={
                  formik.touched.priceInvoice &&
                  Boolean(formik.errors.priceInvoice)
                }
                inputProps={{
                  value: formik.values.priceInvoice,
                  onBlur: formik.handleBlur('priceInvoice'),
                  onChange: formik.handleChange('priceInvoice'),
                }}
                // disabled
              />
              <span>price Paid : </span>
              {formik.values.pricePaid
                ? parseFloat(formik.values.pricePaid).toFixed(2)
                : ''}
            </div>
          )}
        <div style={{ float: 'right', margin: '15px' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleStatus();
            }}
            disabled={disableBtn}
          >
            save
          </Button>
        </div>
      </Box>
    </div>
  );
}
