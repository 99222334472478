import {
  GET_PARTNER_ORDER_REQUEST,
  GET_PARTNER_ORDER_SUCCESS,
  GET_PARTNER_ORDER_FAIL,
  INITIAL_STATE,
  CHANGE_DELIVERIE_STATUS,
  NEW_DELIVERIE,
  COMPLETED_DELIVERIE,
  ASSIGN_DELIVERIE,
  UPDATED_DELIVERIE,
  CANCEL_DELIVERIE,
  CHANGE_DELIVERY_ALARMS,
  // V2 
  GET_PARTNER_ORDER_SUCCESSV2,
  CHANGE_DELIVERIE_STATUSV2,
  ASSIGN_DELIVERIEV2,
  UPDATED_DELIVERIEV2,
  NEW_DELIVERIEV2,
  COMPLETED_DELIVERIEV2,

  GET_PARTNER_ORDER_FAILV2,
  ACCEPTED_DELIVERIEV2,
  CANCEL_DELIVERIEV2,
  INITIAL_STATEV2,
  CHANGE_DELIVERY_ALARMSV2,
  CHANGE_DELIVERYMAN_LOCATIONV2
} from '../constants/order.constants';
import { store } from '../../../../redux/store'
import { orderFormat, rowColor, isClientVip } from '../../mock'
import { getDistanceFromLatLonInKm } from '../../../../helpers/index';

// import { filterSelector } from '../selectors'
// import { useDispatch, useSelector } from 'react-redux';


const initialState = {
  deliveries: [],
  allDeliveries: [],
  deliveriesChangeCounter: 1,
  error: '',
  loading: false,
  contactPhoneCount: {}
};
const filterDeliveries = (deliveries, cities, warehouses) => {
  if (cities.includes('all')) {
    return (deliveries || []).filter(delivery => (warehouses.length > 0 ? warehouses.includes(delivery.pickupAddress.libeleMagasin) : true)).sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    )
  }
  return (deliveries || []).filter(delivery => cities.includes(delivery.cityId) && (warehouses.length > 0 ? warehouses.includes(delivery.pickupAddress.libeleMagasin) : true)).sort(
    (a, b) =>
      new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  )
}
const filterDeliveriesV3 = (deliveries, cities, warehouses, selectedDeliveryManStatus, selectedDispatchingStatus, filterBy) => {
  // Filter by cities
  // console.log('inside filter funct', { cities, warehouses, selectedDeliveryManStatus, selectedDispatchingStatus, filterBy });
  let filteredDeliveries = [];
  if (cities.includes('all')) {
    filteredDeliveries = deliveries || [];
  } else {
    filteredDeliveries = (deliveries || []).filter(delivery => cities.includes(delivery.cityId));
  }

  // Filter by warehouses
  if (warehouses.length > 0) {
    filteredDeliveries = filteredDeliveries.filter(delivery => warehouses.includes(delivery.pickupAddress.libeleMagasin));
  }

  // Filter by status
  if (filterBy == 'deliveryManStatus' && selectedDeliveryManStatus && Object.keys(selectedDeliveryManStatus).length > 0) {

    filteredDeliveries = filteredDeliveries.filter(delivery => {
      const statuses = selectedDeliveryManStatus[delivery.pickupAddress.libeleMagasin];
      return statuses && statuses.includes(delivery.deliveryManStatus);
    });
  }
  if (filterBy == 'dispatchingStatus' && selectedDispatchingStatus && Object.keys(selectedDispatchingStatus).length > 0) {

    filteredDeliveries = filteredDeliveries.filter(delivery => {
      const statuses = selectedDispatchingStatus[delivery.pickupAddress.libeleMagasin];
      return statuses && statuses.includes(delivery.dispatchingStatus);
    });
  }

  // Sort the filtered deliveries
  filteredDeliveries.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

  return filteredDeliveries;
}
export default function deliveriesReducer(state = initialState, action) {
  let deliveries = state.deliveries || []
  let allDeliveries = state.allDeliveries || []
  let contactPhoneCount = state.contactPhoneCount || {};

  switch (action.type) {
    case CHANGE_DELIVERIE_STATUS:
    case ASSIGN_DELIVERIE:
    case UPDATED_DELIVERIE:
      const findIndex = deliveries.findIndex(
        (x) => x._id == action.deliverie._id,
      );
      findIndex != -1 ? deliveries[findIndex] = action.deliverie : null
      break;

    case NEW_DELIVERIE:
      const findIndexForNewDelivery = deliveries.findIndex(x => x._id == action.deliverie?._id)
      findIndexForNewDelivery == -1 && deliveries.push(action.deliverie);
      break;
    case COMPLETED_DELIVERIE:
    case CANCEL_DELIVERIE:
      const findCompletedDeliverie = deliveries.findIndex(
        (x) => x._id == action.deliverieId,
      );
      findCompletedDeliverie != -1 && deliveries.splice(findCompletedDeliverie, 1);
      break;
    case CHANGE_DELIVERY_ALARMS:
      // orders = state.deliveries
      const findIndexForAlarms = deliveries.findIndex(
        (x) => x._id == action.id,
      );
      if (findIndexForAlarms != -1) {
        deliveries[findIndexForAlarms].alarms = action.alarms;
      }
    case GET_PARTNER_ORDER_SUCCESSV2:
      contactPhoneCount = {}
      for (const obj of (action.deliveries || [])) {
        const contactPhone = obj.destinationAddress.contactPhone;
        // contactPhoneCount.set(contactPhone, (contactPhoneCount.get(contactPhone) || 0) + 1);
        contactPhoneCount[contactPhone] = (contactPhoneCount[contactPhone] || 0) + 1
      }

      const result = (action.deliveries || []).map(obj => {
        const contactPhone = obj.destinationAddress.contactPhone;
        // return { ...obj, orderNbr: contactPhoneCount.get(contactPhone) };
        return { ...obj, orderNbr: contactPhoneCount[contactPhone] };
      });

      deliveries = (result || []).map((item, index) => orderFormat(item, index))
      allDeliveries = deliveries
      break;
    case CHANGE_DELIVERIE_STATUSV2:
    case ASSIGN_DELIVERIEV2:
    case UPDATED_DELIVERIEV2:
      // ************************* allDelivery ***************** 
      const findIndexForChangeStatus = allDeliveries.findIndex(x => x._id == action.deliverie._id)

      findIndexForChangeStatus != -1 ? allDeliveries[findIndexForChangeStatus] = orderFormat({
        ...action.deliverie,
        orderNbr: contactPhoneCount[action.deliverie?.destinationAddress.contactPhone],
        clientDistanceCell: allDeliveries[findIndexForChangeStatus]?.clientDistanceCell,
        wareHouseDistanceCell: allDeliveries[findIndexForChangeStatus]?.wareHouseDistanceCell
      }, action.deliverie._id) : null
      // ************************* Delivery ***************** 
      let includedInFilter = false
      // console.log(action?.filterBy, action?.selectedDeliveryManStatus,
      //   action?.selectedDispatchingStatus);
      if (action?.filterBy == 'dispatchingStatus') {
        // console.log("action?.filterBy == 'dispatchingStatus'");
        const statuses = action?.selectedDispatchingStatus[action?.deliverie?.pickupAddress?.libeleMagasin];
        includedInFilter = statuses && statuses.includes(action?.deliverie?.dispatchingStatus);
      } else if (action?.filterBy == 'deliveryManStatus') {
        // console.log("action?.filterBy == 'deliveryManStatus'");
        const statuses = action?.selectedDeliveryManStatus[action?.deliverie?.pickupAddress?.libeleMagasin];
        includedInFilter = statuses && statuses.includes(action?.deliverie?.deliveryManStatus);
      }
      if (action?.filterBy == '' || action?.filterBy == undefined || action?.filterBy == null) {
        // console.log('iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiinsie');
        includedInFilter = true
      }
      // console.log('includedInFilter aftre', includedInFilter);
      if (includedInFilter) {

        const findIndexForChangeStatusFiltred = deliveries.findIndex(x => x._id == action.deliverie._id)

        findIndexForChangeStatusFiltred != -1 ? deliveries[findIndexForChangeStatusFiltred] = orderFormat({
          ...action.deliverie,
          orderNbr: contactPhoneCount[action.deliverie?.destinationAddress.contactPhone],
          clientDistanceCell: deliveries[findIndexForChangeStatusFiltred]?.clientDistanceCell,
          wareHouseDistanceCell: deliveries[findIndexForChangeStatusFiltred]?.wareHouseDistanceCell
        }, action.deliverie._id) :
          allDeliveries.find(x => x._id == action.deliverie._id) &&
          deliveries.push(allDeliveries.find(x => x._id == action.deliverie._id));
        // deliveries.map(obj => {
        //   if (obj.destinationAddress.contactPhone == contactPhoneCountNew) {
        //     obj.orderNbr = contactPhoneCount[contactPhoneCountNew]
        //     obj.Rowcolor = rowColor('', contactPhoneCount[contactPhoneCountNew], action.deliverie?.blocked,
        //       action.deliverie?.rupture,
        //     )
        //   };
        // }
      } else {
        const findCompletedDeliverieV2Filtred = deliveries.findIndex(
          (x) => x._id == action.deliverie?._id,
        );
        findCompletedDeliverieV2Filtred != -1 && deliveries.splice(findCompletedDeliverieV2Filtred, 1);
      }
      break;
    case NEW_DELIVERIEV2:
      // ************************* allDelivery ***************** 
      const findIndexForNewDeliveryV2 = allDeliveries.findIndex(x => x._id == action.deliverie?._id)

      if (findIndexForNewDeliveryV2 == -1) {

        let contactPhoneCountNew = action.deliverie?.destinationAddress.contactPhone
        contactPhoneCount[contactPhoneCountNew] = (contactPhoneCount[contactPhoneCountNew] || 0) + 1

        allDeliveries.push(orderFormat({
          ...action.deliverie, orderNbr: contactPhoneCount[contactPhoneCountNew],
          // Rowcolor: isClientVip(action.deliverie?.destinationAddress?.contactPerson)
          //   ? '#b8860b'
          //   : rowColor('', contactPhoneCount[contactPhoneCountNew], action.deliverie?.blocked,
          //     action.deliverie?.rupture,
          //   )
        }, action.deliverie._id));
        allDeliveries.map(obj => {
          if (obj.destinationAddress.contactPhone == contactPhoneCountNew) {
            obj.orderNbr = contactPhoneCount[contactPhoneCountNew]
            obj.Rowcolor = isClientVip(obj?.destinationAddress?.contactPerson)
              ? '#b8860b'
              : rowColor('', contactPhoneCount[contactPhoneCountNew], action.deliverie?.blocked,
                action.deliverie?.rupture,
              )
          };
        }
        );
      }
      // ***************************** deliveries **************************** 
      let included = false
      // console.log(action?.filterBy, action?.selectedDeliveryManStatus,
      //   action?.selectedDispatchingStatus);
      if (action?.filterBy == 'dispatchingStatus') {
        // console.log("action?.filterBy == 'dispatchingStatus'");
        const statuses = action?.selectedDispatchingStatus[action?.deliverie?.pickupAddress?.libeleMagasin];
        included = statuses && statuses.includes(action?.deliverie?.dispatchingStatus);
      } else if (action?.filterBy == 'deliveryManStatus') {
        // console.log("action?.filterBy == 'deliveryManStatus'");
        const statuses = action?.selectedDeliveryManStatus[action?.deliverie?.pickupAddress?.libeleMagasin];
        included = statuses && statuses.includes(action?.deliverie?.deliveryManStatus);
      }

      // selectedDeliveryManStatus,
      //   selectedDispatchingStatus,
      if (action?.filterBy == '' || action?.filterBy == undefined || action?.filterBy == null) {

        included = true
      }
      // console.log('included', included);
      if (included) {
        const findIndexForNewDeliveryV2 = deliveries.findIndex(x => x._id == action.deliverie?._id)

        if (findIndexForNewDeliveryV2 == -1) {

          let contactPhoneCountNew = action.deliverie?.destinationAddress.contactPhone
          // contactPhoneCount[contactPhoneCountNew] = (contactPhoneCount[contactPhoneCountNew] || 0) + 1

          deliveries.push(orderFormat({ ...action.deliverie, orderNbr: contactPhoneCount[contactPhoneCountNew] }, action.deliverie._id));
          deliveries.map(obj => {
            if (obj.destinationAddress.contactPhone == contactPhoneCountNew) {
              obj.orderNbr = contactPhoneCount[contactPhoneCountNew]
              obj.Rowcolor = isClientVip(obj?.destinationAddress?.contactPerson)
                ? '#b8860b'
                : rowColor('', contactPhoneCount[contactPhoneCountNew], action.deliverie?.blocked,
                  action.deliverie?.rupture,
                )
            };
          }
          );
        }
      }

      break;
    case COMPLETED_DELIVERIEV2:
    case CANCEL_DELIVERIEV2:
      // ************************* allDelivery ***************** 
      const findCompletedDeliverieV2 = allDeliveries.findIndex(
        (x) => x._id == action.deliverieId,
      );
      findCompletedDeliverieV2 != -1 && allDeliveries.splice(findCompletedDeliverieV2, 1);
      contactPhoneCount[action?.contact] > 1 ? contactPhoneCount[action?.contact] -= 1 : delete contactPhoneCount[action?.contact]
      const findIfClientHaveOrderAfterThis = allDeliveries.find(w => w.destinationAddress?.contactPhone == action?.contact)
      // console.log('findIfClientHaveOrderAfterThis', findIfClientHaveOrderAfterThis);
      if (findIfClientHaveOrderAfterThis) {
        allDeliveries.map(obj => {
          if (obj.destinationAddress.contactPhone == action?.contact) {
            obj.orderNbr = contactPhoneCount[action?.contact]
            obj.Rowcolor = isClientVip(obj?.destinationAddress?.contactPerson)
              ? '#b8860b'
              : rowColor('', contactPhoneCount[action?.contact], '', '')
          };
        }
        );
      }
      // ************************* delivery ***************** 
      const findCompletedDeliverieV2Filtred = deliveries.findIndex(
        (x) => x._id == action.deliverieId,
      );
      findCompletedDeliverieV2Filtred != -1 && deliveries.splice(findCompletedDeliverieV2Filtred, 1);
      // contactPhoneCount[action?.contact] > 1 ? contactPhoneCount[action?.contact] -= 1 : delete contactPhoneCount[action?.contact]
      const findIfClientHaveOrderAfterThisFiltred = deliveries.find(w => w.destinationAddress?.contactPhone == action?.contact)
      // console.log('findIfClientHaveOrderAfterThisFiltred', findIfClientHaveOrderAfterThisFiltred);
      if (findIfClientHaveOrderAfterThisFiltred) {
        deliveries.map(obj => {
          if (obj.destinationAddress.contactPhone == action?.contact) {
            obj.orderNbr = contactPhoneCount[action?.contact]
            obj.Rowcolor = isClientVip(obj?.destinationAddress?.contactPerson)
              ? '#b8860b'
              : rowColor('', contactPhoneCount[action?.contact], '', '')
          };
        }
        );
      }
      break;
    case CHANGE_DELIVERYMAN_LOCATIONV2:
      if (action.location && action.location.length > 0 && allDeliveries && allDeliveries.length > 0 && allDeliveries.find(x => x?.deliveryMan?._id == action.deliveryManId)) {

        allDeliveries.map(x => {
          if (x?.deliveryMan?._id == action.deliveryManId) {
            x.deliveryManLocation = action.location
            x.clientDistanceCell = getDistanceFromLatLonInKm(action.location[0], action.location[1], x?.destinationAddress?.latitude, x?.destinationAddress?.longitude)
            x.wareHouseDistanceCell = getDistanceFromLatLonInKm(action.location[0], action.location[1], x?.pickupAddress?.latitude, x?.pickupAddress?.longitude)
          };
        })
      }
      if (action.location && action.location.length > 0 && deliveries && deliveries.length > 0 && deliveries.find(x => x?.deliveryMan?._id == action.deliveryManId)) {

        deliveries.map(x => {
          if (x?.deliveryMan?._id == action.deliveryManId) {
            x.deliveryManLocation = action.location
            x.clientDistanceCell = getDistanceFromLatLonInKm(action.location[0], action.location[1], x?.destinationAddress?.latitude, x?.destinationAddress?.longitude)
            x.wareHouseDistanceCell = getDistanceFromLatLonInKm(action.location[0], action.location[1], x?.pickupAddress?.latitude, x?.pickupAddress?.longitude)
          };
        })
      }
      break;
    default:
      break;
  }
  switch (action.type) {
    case GET_PARTNER_ORDER_REQUEST:
      return {
        loading: true,
        deliveriesChangeCounter: (state.deliveriesChangeCounter + 1) % 100,
      };

    case GET_PARTNER_ORDER_SUCCESS:

      return {
        ...state,
        loading: false,
        deliveriesChangeCounter: (state.deliveriesChangeCounter + 1) % 100,
        deliveries: filterDeliveries(action.deliveries, action.cities, action.warehouses)
      };
    case GET_PARTNER_ORDER_FAIL:
      return {
        ...state,
        loading: false,
        error:
          action.error?.message ||
          (action?.error?.errors || [{ message: '' }])[0].message,
      };
    case CHANGE_DELIVERIE_STATUS:
    case NEW_DELIVERIE:
    case COMPLETED_DELIVERIE:
    case CANCEL_DELIVERIE:
    case ASSIGN_DELIVERIE:
    case UPDATED_DELIVERIE:
    case CHANGE_DELIVERY_ALARMS:
      return {
        ...state,
        loading: false,
        deliveries: deliveries.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        ),
        deliveriesChangeCounter: (state.deliveriesChangeCounter + 1) % 100,
      };
    case INITIAL_STATE:
      return {
        ...state,
        loading: false,
        deliveries: [],
        allDeliveries: [],
        contactPhoneCount: {}
      };
    case GET_PARTNER_ORDER_SUCCESSV2:
      return {
        ...state,
        loading: false,
        deliveriesChangeCounter: (state.deliveriesChangeCounter + 1) % 100,
        deliveries: filterDeliveriesV3(deliveries, action.cities, action.warehouses, action?.selectedDeliveryManStatus, action?.selectedDispatchingStatus, action?.filterBy),
        allDeliveries: allDeliveries,
        contactPhoneCount: contactPhoneCount
      };
    case CHANGE_DELIVERIE_STATUSV2:
    case NEW_DELIVERIEV2:
    case COMPLETED_DELIVERIEV2:
    case CANCEL_DELIVERIEV2:
    case ASSIGN_DELIVERIEV2:
    case UPDATED_DELIVERIEV2:
    case CHANGE_DELIVERYMAN_LOCATIONV2:
      // case CHANGE_DELIVERY_ALARMSV2:
      return {
        ...state,
        loading: false,
        deliveries: deliveries.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        ),
        allDeliveries: allDeliveries.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        ),
        deliveriesChangeCounter: (state.deliveriesChangeCounter + 1) % 100,
        contactPhoneCount: contactPhoneCount
      };

    default:
      return state;
  }
}
